/*=| 色の設定 |=*/
:root {
  --mainColor: #6F0C6B;
  --subColor: #920783;
  --white: #FFFFFF;
  --black: #000000;
  --red: #FF0000;
  --transparent: #00000000;
  /*--*/
  --eventChcket: #BF9000;
  --eventGame: #FF2540;
  --sundayRed: #FF0000;
  --saturdayBlue: #0000FF;
  --holidayRed: #A00;
}