@import './color.css';
@import './resource.css';

/* ===== */
#schedule.body {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: var(--img-back-ground);
}

#selector.body {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  align-items: center;
  width: 65%;
}

#selector.body>div {
  margin: 0px auto;
}

#selector.title {
  color: var(--white);
  font-size: 4em;
  font-weight: bold;
  /* iPhoneのsafariで自動文字サイズ調整を無効にする設定 */
  -webkit-text-size-adjust: 100%;
}

.month-button {
  color: var(--white);
  background-color: var(--transparent);
  cursor: pointer;
  border: 0px;
  font-size: 4em;
  font-weight: bold;
}

.month-button:disabled {
  color: var(--transparent);
  background-color: var(--transparent);
  cursor: auto;
  border: 0px;
}

#team-label.body {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 10px 0px;
}

img.icon {
  height: 50px;
  width: auto;
  margin-right: 10px;
}

img.text {
  height: 40px;
  width: auto;
}

#dialog.server-error {
  background-color: var(--white);
  border-radius: 10px 10px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-width: 5px;
  border-color: #000000;
  z-index: 110;
}

#dialog.bg-cover {
  position: fixed;
  background-color: #00000080;
  width: 100%;
  height: 100%;
  z-index: 100;
}

#dialog.body {
  font-size: 2em;
  text-align: center;
  margin-left: 15px;
  margin-right: 15px;
}

.grid-area {
  display: grid;
  height: 100vh;
  grid-template-rows: auto 1fr;
}

.header-area {
  grid-row: 1;
  grid-column: 1;
}

.calendar-area {
  grid-row: 2;
  grid-column: 1;
  overflow-y: scroll;
}

.boundary-line {
  grid-row: 3;
  grid-column: 1;

  width: 100%;
  height: 10px;
  background-color: darkgray;
  display: flex;
  justify-content: center;
  align-items: center;
}

.detail-area {
  grid-row: 4;
  grid-column: 1;
  overflow-y: scroll;
}

.dayofweek {
  width: 95%;
  height: fit-content;
}