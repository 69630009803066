@import './color.css';
@import './calendarCell.css';

/* ===== */
#calendar.body{
  width: 95%;
  height: fit-content;
}

#calendar.header{
  display: flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  margin-bottom: 4px;

}
#calendar.week-line{
  display: flex;
  flex-direction:row;
  margin-bottom: 4px;
}

/* -- */
#header.cell{
  flex-grow: 1;
  flex: 1;
  text-align: center;
  font-size: xx-large;
  font-weight: bold;
  color: var(--white);
  border: 2px var(--white) solid;
  background-color: var(--subColor);
  margin-right: 4px;
}
#header.cell.saturday{
  background-color: var(--saturdayBlue);
}
#header.cell.sunday{
  background-color: var(--sundayRed);
  margin-right: 0px;
}
/*  */


/*
 align-items: center;
justify-content: center;
 */