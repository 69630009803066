@import './color.css';
/* ===== */

#event-detail.body {
  width: 100%;
  height: 24rem;
  background-color: white;
  overflow-y: auto;
}

#event-item.body {
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  border-bottom: 2px solid black;
}

#event-item.icon-area {
  position: relative;
  margin-right: 60px;
  margin-left: 10px;
  height: 120px;
  width: 120px;
  min-width: 120px;
}

#event-item.icon {
  position: absolute;
  object-fit: contain;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}

#event-item.info {
  line-height: 1.5;
  font-size: 2.5em;
  font-weight: bold;
  margin-right: auto;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-all;
  width: -webkit-fill-available;
}

#event-item.vs {
  line-height: 1.5;
  font-size: 2.5em;
  font-weight: bold;
  margin-right: 10px;
  width: fit-content;
}

#event-item.opponent {
  line-height: 1.5;
  font-size: 2.5em;
  font-weight: bold;
  margin-right: 40px;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-all;
  width: fit-content;
}

#event-item.time {
  line-height: 1.5;
  font-size: 2.5em;
  font-weight: bold;
  margin-right: auto;
}

.link-button {
  color: var(black);
  background-color: #00000000;
  cursor: pointer;
  border: 0px;
  font-size: 4em;
  margin-left: auto;
  padding-right: 30px;
}

#no-events {
  width: 95%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 20px;
  font-size: 2.5em;
}