@import './color.css';

/* ===== */
#calendar-cell.body {
  position: relative;
  flex: 1;
  aspect-ratio: 3/4.5;
  border: 3px var(--subColor) solid;
  background-color: var(--white);
  margin-right: 4px;
}

#calendar-cell.body.sunday {
  margin-right: 0px;
}

/* -- */
/* 枠 */
.selected-frame {
  position: absolute;
  box-sizing: border-box;
  border: 4px var(--red) solid;
  width: 100%;
  height: 100%;
}

/* -- */
/* 日付などの表示領域 */
#calendar-cell.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 3px 3px;
}

#calendar-cell.day {
  font-size: medium;
  margin-left: 3%;
  font-size: xx-large;
  font-weight: bold;
  width: 40%;
}

#calendar-cell.day.holiday {
  color: var(--holidayRed);
}

#calendar-cell.game-type {
  position: relative;
  padding: 2px 3px;
  margin-right: 3%;
  margin-top: 5px;
  width: 50%;
}

.game-type.home {
  object-fit: contain;
}

.game-type.away {
  object-fit: contain;
}

/* -- */
/* 大アイコン表示領域 */
#calendar-cell.event-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2px;
  text-align: center;
}

#event-main.icon-base {
  width: 70%;
  height: 60px;
  aspect-ratio: 1/1;
}

#event-main.name-text {
  font-size: 2ch;
  margin-top: 3px;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  font-weight: bold;
  word-break: break-all;
}

#event-main.calendar-cell-icon {
  height: 60px;
  width: auto;
}

/* イベント情報表示領域 */
#calendar-cell.text-base {
  height: fit-content;
  width: 100%;
  margin-top: 3px;
}

#calendar-cell.event-text {
  text-align: center;
  font-size: medium;
  font-weight: bold;
  text-align: left;
  padding: 0px 6px;
  align-items: center;
  justify-content: center;
  height: 40px;
  line-height: 40px;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: white;
  word-break: break-all;
}

/* 小アイコン表示領域 */
#calendar-cell.event-line {
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  box-sizing: border-box;
  margin: 2px 0px;
  padding-left: 4px;
  align-items: center;
}

#event-line.icon {
  height: 100%;
  width: 24%;
  object-fit: contain;
  margin: 0px;
}